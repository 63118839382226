@charset "UTF-8";
/*@font-face {
    font-family: "designio";
    src: url("Designio\ Medium.otf") format("truetype");
}
body {
    background-color: #f1f1f1;
    font-family: "designio";
}*/
body {
  background-color: #f1f1f1;
  overflow: hidden; }

/* Menu déroulant principal */
.example-wrapper {
  margin: 1em auto;
  max-width: 800px;
  width: 95%;
  font: 18px/1.5 sans-serif; }

.example-wrapper code {
  background: #F5F5F5;
  padding: 2px 6px; }

#logo-navbar {
  width: 110%;
  margin-left: -5%;
  margin-top: 11%; }

#contain-logo-navbar {
  width: 20%; }

ul.bts-nav li > ul {
  margin-bottom: 10px; }

.navbar-inverse .navbar-nav > li:hover a {
  color: #000 !important; }

.navbar-inverse .navbar-nav > .active > a:hover {
  color: #fff !important; }

.navbar-inverse {
  border: none !important;
  border-radius: 0 !important; }

ul.bts-nav .dropdown-menu {
  padding: 0px;
  background: #E7E7E7;
  border: none;
  box-shadow: none;
  border-radius: 0;
  left: 0; }

ul.bts-nav li:hover {
  background: #E7E7E7; }

ul.bts-nav li > ul > li > ul.dropdown-menu {
  left: 160px;
  top: -3px; }

ul.bts-nav .dropdown-menu > li > a {
  padding: 10px 20px; }

ul.bts-nav .dropdown-caret {
  transform: rotate(-90deg); }

#navbarMenu .bts-nav {
  margin-left: 20%; }

body {
  color: #7991bd; }

.navbar {
  color: #7991bd; }

.body-wrap {
  color: #7991bd; }

.navbar-inverse .navbar-form {
  border-color: #7991bd;
  background-color: #7991bd; }

.navbar-inverse {
  margin-top: -2.7%;
  background-color: #475387; }

.color-dropdown:nth-child(1) {
  background-color: #475387; }

.color-dropdown:nth-child(2) {
  background-color: #ee862f; }

.color-dropdown:nth-child(3) {
  background-color: #7a327b; }

.color-dropdown:nth-child(4) {
  background-color: #74b43c; }

.color-dropdown:nth-child(5) {
  background-color: #827c70; }

.color-dropdown:nth-child(6) {
  background-color: #adaaa6; }

.navbar-inverse .navbar-nav > li > a {
  color: white; }

#navbarMenu .bts-nav {
  width: 100%; }

.colored-li {
  width: 22%;
  background-color: #adaaa6; }

.colored-li2 {
  width: 8%;
  background-color: #adaaa6; }

body {
  overflow-x: hidden; }

.colored-li a:hover {
  cursor: default; }

ul.bts-nav li:nth-child(7):hover {
  background-color: #adaaa6; }

/* Entête de toutes les pages */
#HeaderRow {
  margin-top: 5%;
  margin-left: 4%;
  text-align: center;
  width: 92%; }

#HeaderRow p {
  text-align: center;
  color: black; }

#HeaderRow p input {
  text-align: center; }

#HeaderRow div {
  vertical-align: center; }

#HeaderRow select {
  width: 100%; }

#InputMesures input {
  width: 30%; }

.onInput {
  font-weight: bold; }

#BoutonPresentsSortis {
  margin-top: 15%; }

#BoutonDocuments, #BoutonPrevisionnel {
  margin-top: 15%; }

.swal2-popup {
  font-size: 1.0rem !important; }

#FormSalarie {
  text-align: center; }

#FormSalarie input {
  width: 100%; }

.footer {
  background-color: #475387;
  height: 6%;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%; }

.footer input {
  margin-top: 4%;
  width: 80%; }

#FormSalarie {
  margin-top: 3.5%; }

#FormSalarie .rowTop {
  margin-top: 60px; }

.btnFooter:hover {
  background-color: #cad2e5 !important; }

.moveMesure:hover {
  cursor: pointer; }

.pretty input {
  top: 20px; }
